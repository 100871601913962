import React from "react"
import styled from "styled-components"

import ArrowRight from "../../images/arrow-right.svg"
import { useTranslateValue } from "../../data/TranslateContext"

const Wrapper = styled.div`
  margin: 0 auto;
  max-width: 50rem;
`

const Header = styled.header`
  padding: 0.5rem 2rem;

  border-top: 2px solid ${props => props.theme.color.primary};
  border-bottom: 2px solid ${props => props.theme.color.primary};

  font-size: 0.9em;
  color: ${props => props.theme.color.primary};
`

const Result = styled.div`
  padding: 1.5rem 2rem;

  color: ${props => props.theme.color.primary};
`

const Address = styled.div`
  margin: 0 0 1rem;
`

const DirectionsLink = styled.a`
  display: flex;
  align-items: center;
  margin-top: 0.25rem;

  svg {
    display: block;
    margin-left: 0.5rem;
  }
`

export default function ResultsList(props) {
  const { results } = props

  const [{ language }] = useTranslateValue()

  return (
    <Wrapper>
      {language === "en" && <Header>{results.length} Local Results</Header>}
      {language === "fr" && <Header>{results.length} résultats locaux</Header>}

      {results.map((result, resultIndex) => {
        // console.log({ result })
        const distance = parseFloat(result.distance).toFixed(2)
        const distanceKm = parseFloat(distance * 1.6).toFixed(2)
        const directionsLink = `https://www.google.com/maps/dir/Current+Location/${result.latitude},${result.longitude}`

        return (
          <Result key={resultIndex}>
            <strong>
              {resultIndex + 1}. {result.store_locator_name}
            </strong>
            <Address>
              <div>{result.address_1}</div>
              <div>
                {result.city}, {result.state} {result.zip}
              </div>
            </Address>
            <strong>{distanceKm} km away</strong>
            <DirectionsLink
              href={directionsLink}
              target="_blank"
              rel="noopener noreferrer"
            >
              {language === "en" && "Get Directions"}
              {language === "fr" && "Obtenir des directions"}
              <ArrowRight />
            </DirectionsLink>
          </Result>
        )
      })}
    </Wrapper>
  )
}
