import base from "./base"

const themeColors = {
  primary: "#562C79",
  primaryBackground: "#562C79",
  secondary: "#FAEEBD",
  highlight: "#F18CF5",

  whiteBg: "#FCEAFF",
  lightBg: "#FBDCFF",
  mediumBg: "#EBC6EC",
  darkBg: "#DD8FE0",

  bannerBg: "#80388C",
  navigationBg: "#FCEAFF",
  logoBg: "#562C79",

  heroButtonBg1: "#AB55AF",
  heroButtonBg2: "#CF70D3",
  heroButtonBg3: "#F18CF5",
}

const proteinColor = Object.assign(base.color, themeColors)
const protein = { ...base, color: { ...proteinColor } }

export default protein
