import React from "react"
import styled from "styled-components"

import { useStateValue } from "../../data/StateContext"
import FinderModal from "../FinderModal"

import Transition from "./Transition"
import Row from "../../styles/Row"
import Finder from "./Finder"
import Colophon from "./Colophon"
import Nav from "./Nav"
import Contact from "./Contact"

const Wrapper = styled.footer`
  position: relative;
  margin-top: -2px;

  background-color: ${props => props.theme.color.primaryBackground};
  transition: background 0.2s ease-in-out;
`

const Columns = styled(Row)`
  display: flex;
  flex-wrap: wrap;
  padding-bottom: 2rem;
`
const Column = styled.div`
  flex-basis: 24rem;
  flex-grow: 3;
  margin-bottom: 2rem;

  color: #fff;

  a {
    color: ${props => props.theme.color.secondary};
  }
`

const LeftColumn = styled(Column)`
  @media (min-width: 641px) {
    margin-right: 2rem;
  }
  @media (max-width: 640px) {
    order: 3;
  }
`

const RightColumn = styled(Column)`
  display: flex;
  flex-wrap: wrap;
  flex-grow: 1;
  justify-content: flex-end;

  @media (max-width: 640px) {
    order: 1;
  }
`

export default function Footer(props) {
  const [{ finderModalVisible }] = useStateValue()

  return (
    <>
      <Transition />

      <Wrapper>
        <Columns>
          <LeftColumn>
            <Finder />
            <Colophon />
          </LeftColumn>

          <RightColumn>
            <Nav />
            <Contact />
          </RightColumn>
        </Columns>
      </Wrapper>

      {finderModalVisible && <FinderModal />}
    </>
  )
}
