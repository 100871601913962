import React from "react"
import { Link } from "gatsby"
import styled from "styled-components"

import { useTranslateValue } from "../../data/TranslateContext"
import FooterLogo from "../../images/footer-logo.svg"

const Wrapper = styled.div`
  margin-top: 4rem;
  padding-bottom: 2rem;

  a:hover {
    text-decoration: underline;
  }
`

const LogoWrapper = styled.div`
  margin-bottom: 0.5rem;

  path {
    transition: all 0.2s ease-in-out;
  }

  .light-bg {
    /* fill: ${props => props.theme.color.lightBg}; */
    fill: #fff;
  }

  .logo-bg {
    fill: ${props => props.theme.color.primaryBackground};
  }
`

export default function Colophon(props) {
  const [{ language }] = useTranslateValue()

  return (
    <Wrapper>
      <LogoWrapper>
        <FooterLogo />
      </LogoWrapper>
      <p>
        &copy; {new Date().getFullYear()} &bull; f'real foods LLC &bull;{" "}
        {language === "en" && (
          <>
            <Link to="/privacy-policy">Privacy Policy</Link> &bull;{" "}
            <Link to="/terms-and-conditions">Terms &amp; Conditions</Link>
          </>
        )}
        {language === "fr" && (
          <>
            <Link to="/privacy-policy">Politique de confidentialité</Link>{" "}
            &bull; <Link to="/terms-and-conditions">Termes et Conditions</Link>
          </>
        )}
      </p>
    </Wrapper>
  )
}
