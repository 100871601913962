import React, { useState } from "react"
import { Link } from "gatsby"
import { CSSTransition } from "react-transition-group"
import styled from "styled-components"

import Logo from "../../images/nav-logo.svg"
import TouchModal from "./TouchModal"

const Header = styled.header`
  position: fixed;
  z-index: 100;
  left: 0;
  top: 0;

  display: none;
  justify-content: space-between;
  width: 100%;

  background-color: ${props => props.theme.color.primary};
  transition: background 0.2s ease-in-out;

  @media (max-width: 900px) {
    display: flex;
  }

  .logo-wrapper {
    position: relative;
    z-index: 20;
    align-self: flex-start;
    margin-left: 1rem;
  }

  .logo {
    display: flex;
    padding: 0.5rem;

    svg {
      display: block;
      width: auto;
      height: 60px;

      path {
        transition: all 0.2s ease-in-out;
      }

      .primary {
        fill: #fff;
        transition: all 0.2s ease-in-out;
      }

      .secondary {
        fill: ${props => props.theme.color.primary};
        transition: all 0.2s ease-in-out;
      }
    }
  }

  .touch-menu {
    transform: translateY(-100%);
    transition: all 0.3s ease-in-out;

    &-enter {
      transform: translateY(-100%);
      /* opacity: 0.5; */

      &-done {
        transform: translateY(0);
        /* opacity: 1; */
      }
    }
    &-exit {
      transform: translateY(0);
      /* opacity: 1; */

      &-done {
        transform: translateY(-100%);
        /* opacity: 0.5; */
      }
    }
  }
`

const hamburgerPaddingX = 16
const hamburgerPaddingY = 16
const hamburgerLayerWidth = 40
const hamburgerLayerHeight = 5
const hamburgerLayerSpacing = 8
// const hamburgerLayerColor = "#F00"
const hamburgerLayerBorderRadius = 10
const hamburgerHoverOpacity = 1
const hamburgerActiveLayerColor = "#000"
const hamburgerActiveHoverOpacity = 1

const HamburgerWrapper = styled.div`
  position: relative;
  z-index: 20;

  display: flex;
  align-items: center;
  justify-content: center;
  padding-right: 0.5rem;

  .hamburger {
    padding: ${hamburgerPaddingY}px ${hamburgerPaddingX}px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    transition-property: opacity, filter;
    transition-duration: 1s;
    transition-timing-function: linear;

    font: inherit;
    color: inherit;
    text-transform: none;
    background-color: transparent;
    border: 0;
    margin: 0;
    overflow: visible;

    &:hover {
      opacity: ${hamburgerHoverOpacity};
    }

    &[data-active="true"] {
      &:hover {
        opacity: ${hamburgerActiveHoverOpacity};
      }
    }
  }

  .hamburger-inner,
  .hamburger-inner::before,
  .hamburger-inner::after {
    background-color: ${hamburgerActiveLayerColor};
  }

  .hamburger-box {
    width: ${hamburgerLayerWidth}px;
    height: ${hamburgerLayerHeight * 3 + hamburgerLayerSpacing * 2}px;
    display: inline-block;
    position: relative;
  }

  .hamburger-inner {
    display: block;
    top: 50%;
    margin-top: ${hamburgerLayerHeight / -2}px;

    &,
    &::before,
    &::after {
      width: ${hamburgerLayerWidth}px;
      height: ${hamburgerLayerHeight}px;
      background-color: ${props => props.theme.color.whiteBg};
      border-radius: ${hamburgerLayerBorderRadius}px;
      position: absolute;
      transition-property: transform;
      transition-duration: 1s;
      transition-timing-function: ease;
    }

    &::before,
    &::after {
      content: "";
      display: block;
    }

    &::before {
      top: -${hamburgerLayerSpacing + hamburgerLayerHeight}px;
    }

    &::after {
      bottom: -${hamburgerLayerSpacing + hamburgerLayerHeight}px;
    }
  }

  .hamburger {
    .hamburger-inner {
      top: auto;
      bottom: 0;
      transition-duration: 0.13s;
      transition-delay: 0.13s;
      transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);

      &::after {
        top: -${hamburgerLayerSpacing * 2 + hamburgerLayerHeight * 2}px;
        transition: top 0.2s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1),
          opacity 0.1s linear;
      }

      &::before {
        transition: top 0.12s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1),
          transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19);
      }
    }

    &[data-active="true"] {
      .hamburger-inner {
        transform: translate3d(
            0,
            -${hamburgerLayerSpacing + hamburgerLayerHeight}px,
            0
          )
          rotate(-45deg);
        transition-delay: 0.22s;
        transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);

        &::after {
          top: 0;
          opacity: 0;
          transition: top 0.2s cubic-bezier(0.33333, 0, 0.66667, 0.33333),
            opacity 0.1s 0.22s linear;
        }

        &::before {
          top: 0;
          transform: rotate(-90deg);
          transition: top 0.1s 0.16s cubic-bezier(0.33333, 0, 0.66667, 0.33333),
            transform 0.13s 0.25s cubic-bezier(0.215, 0.61, 0.355, 1);
        }
      }
    }
  }
`

const Modal = styled.div`
  position: absolute;
  z-index: 10;
  left: 0;
  top: 0;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 1rem;
  width: 100%;
  height: 100vh;

  background-color: ${props => props.theme.color.primary};
`
export default function Touch(props) {
  const [active, setActive] = useState(false)

  return (
    <Header>
      <div className={`logo-wrapper`}>
        <Link
          to="/"
          className={`logo`}
          onClick={e => {
            setActive(false)
          }}
        >
          <Logo data-active={active} />
        </Link>
      </div>

      <HamburgerWrapper>
        <button
          className="hamburger"
          data-active={active}
          onClick={e => {
            setActive(!active)
          }}
        >
          <span className="hamburger-box">
            <span className="hamburger-inner"></span>
          </span>
        </button>
      </HamburgerWrapper>

      <CSSTransition
        in={active}
        appear={active}
        timeout={300}
        className="touch-menu"
        classNames="touch-menu"
      >
        <Modal>
          <TouchModal setActive={setActive} />
        </Modal>
      </CSSTransition>
    </Header>
  )
}
