import React from "react"
import { Link } from "gatsby"
import styled from "styled-components"

import { hex2rgba } from "../../styles/tools"
import Row from "../../styles/Row"
import Logo from "../../images/nav-logo.svg"
import IconFlavors from "../../images/nav-flavors.svg"
import IconHowItWorks from "../../images/nav-how-it-works.svg"
import IconOurStory from "../../images/nav-our-story.svg"
import IconFinder from "../../images/nav-finder.svg"

import { useTranslateValue } from "../../data/TranslateContext"

const NavWrapper = styled.div`
  background-color: ${props => props.theme.color.navigationBg};
  transition: background 0.2s ease-in-out;

  box-shadow: 0 2px 8px
    ${props => {
      return hex2rgba(props.theme.color.bannerBg, 0.2)
    }};

  @media (max-width: 900px) {
    display: none;
  }
`
NavWrapper.row = styled(Row)`
  display: flex;
  align-items: center;
  justify-content: space-between;

  .logo-wrapper {
    position: relative;
    align-self: flex-start;

    height: 1.125em;
  }

  .logo {
    position: absolute;
    top: 0.8em;
    left: 0;

    position: relative;

    display: flex;
    align-self: stretch;
    align-items: center;
    justify-content: center;

    svg {
      display: block;
      height: 85px;

      .primary {
        fill: ${props => props.theme.color.logoBg};
        transition: all 0.2s ease-in-out;
      }
    }
  }

  nav {
    display: flex;

    a {
      display: flex;
      align-items: center;
      padding: 0.675rem 1rem;

      text-transform: uppercase;
      font-size: 1.375em;
      font-family: ${props => props.theme.font.heading};
      color: ${props => props.theme.color.primary};
      transition: color 0.2s ease-in-out;

      &:hover {
        color: ${props => props.theme.color.primary};
      }

      svg {
        flex-shrink: 0;
        display: block;
        margin-right: 0.325rem;

        height: 36px;

        path {
          fill: ${props => props.theme.color.primary};
          transition: all 0.2s ease-in-out;

          &.has-stroke {
            stroke: ${props => props.theme.color.primary};
          }
        }
      }
    }
  }
`

export default function Navigation(props) {
  const [{ language }] = useTranslateValue()

  return (
    <NavWrapper>
      <NavWrapper.row>
        <div className={`logo-wrapper`}>
          <Link to="/" className={`logo`}>
            <Logo />
          </Link>
        </div>

        <nav>
          <Link to="/flavors" data-page={`flavors`}>
            <IconFlavors />
            {language === "en" && "The Flavors"}
            {language === "fr" && "Les saveurs"}
          </Link>
          <Link to="/how-it-works" data-page={`how-it-works`}>
            <IconHowItWorks />
            {language === "en" && "How It Works"}
            {language === "fr" && "Comment ça Fonctionne"}
          </Link>
          <Link to="/our-story" data-page={`our-story`}>
            <IconOurStory />
            {language === "en" && "Our Story"}
            {language === "fr" && "Notre Histoire"}
          </Link>
          <Link to="/finder" data-page={`finder`}>
            <IconFinder />
            {language === "en" && "Find a f'real"}
            {language === "fr" && "Trouvez un f'real"}
          </Link>
        </nav>
      </NavWrapper.row>
    </NavWrapper>
  )
}
