import React from "react"
import { Link } from "gatsby"
import styled from "styled-components"

import { useTranslateValue } from "../../data/TranslateContext"

const Wrapper = styled.nav`
  flex-basis: 11rem;
  flex-grow: 1;
  margin-right: 2rem;
  margin-bottom: 2rem;

  a {
    display: block;
    padding: 0.25em 0;

    &:hover {
      text-decoration: underline;
    }
  }
`

export default function Nav(props) {
  const [{ language }] = useTranslateValue()

  return (
    <Wrapper>
      {language === "en" && (
        <>
          <a href="https://business.freal.com">Retailers</a>
          <Link to="/faqs">FAQs</Link>
          <a href="https://careers.freal.com">Careers</a>
          <Link to="/our-story">Our Story</Link>
          <Link to="/patents">Patents</Link>
        </>
      )}

      {language === "fr" && (
        <>
          <a href="https://business.freal.com">Détaillants</a>
          <Link to="/faqs">Foire aux questions</Link>
          <a href="https://careers.freal.com">Carrières</a>
          <Link to="/our-story">Notre Histoire</Link>
          <Link to="/patents">Brevets</Link>
        </>
      )}
    </Wrapper>
  )
}
