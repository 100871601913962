import React from "react"
import styled from "styled-components"

import { hex2rgba } from "../../styles/tools"
import { useTranslateValue } from "../../data/TranslateContext"

const Wrapper = styled.div`
  position: absolute;
  z-index: 10;
  left: 0;
  top: 0;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: 0 1rem 2rem;
  width: 100%;
  height: 400px;

  background-color: ${props => hex2rgba(props.theme.color.lightBg, 0.8)};

  &::before {
    position: absolute;
    left: 0;
    bottom: 0;

    display: block;
    width: 100%;
    height: 4rem;

    content: "";

    background-image: linear-gradient(
      to bottom,
      ${props => hex2rgba(props.theme.color.lightBg, 0.5)} 0%,
      ${props => props.theme.color.lightBg} 100%
    );
  }
`

const Title = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 400px;

  header {
    margin-bottom: 1rem;

    font-family: ${props => props.theme.font.heading};
    font-size: 3em;
    line-height: 1em;
    color: ${props => props.theme.color.primary};
    text-align: center;
    text-shadow: 1px 2px 3px 1px rgba(255, 255, 255, 0.8);

    @media (max-width: 640px) {
      font-size: 2em;
    }
  }
`
const Actions = styled.div`
  display: flex;
  /* border: 1px solid red; */
`
const Button = styled.button`
  display: block;
  margin: 0.75rem;
  padding: 1rem 1.5rem 0.7rem;

  border: none;
  border-radius: 20rem;
  background-color: ${props => props.theme.color.primary};

  font-family: ${props => props.theme.font.heading};
  font-size: 1.8em;
  color: ${props => props.theme.color.whiteBg};

  transition: all 0.2s ease-in-out;

  @media (max-width: 640px) {
    font-size: 1.2em;
  }

  &[data-primary="true"] {
    background-color: ${props => props.theme.color.primaryBackground};
  }

  &:hover {
    color: ${props => props.theme.color.secondary};
  }
`

export default function PermissionMask(props) {
  const { getNavPermission, setShowMap } = props

  const [{ language }] = useTranslateValue()

  return (
    <Wrapper>
      <Title>
        {language === "en" && (
          <header>
            Would you like us to
            <br />
            find you automatically?
          </header>
        )}
        {language === "fr" && (
          <header>
            Souhaitez-vous que nous vous
            <br />
            trouvions automatiquement?
          </header>
        )}
        <Actions>
          <Button
            data-primary={true}
            onClick={e => {
              getNavPermission(true)
            }}
          >
            {language === "en" && "Yes, please!"}
            {language === "fr" && "Oui s'il te plaît!"}
          </Button>
          <Button
            onClick={e => {
              getNavPermission(false)
              setShowMap(false)
            }}
          >
            {language === "en" && "No thanks"}
            {language === "fr" && "Non merci"}
          </Button>
        </Actions>
      </Title>
    </Wrapper>
  )
}
