import React, { createContext, useContext, useReducer } from "react"

export const initialTranslateState = {
  language: "en",
}

export const TranslateContext = createContext(initialTranslateState)

export const TranslateProvider = ({ reducer, initialState, children }) => (
  <TranslateContext.Provider value={useReducer(reducer, initialState)}>
    {children}
  </TranslateContext.Provider>
)

export const useTranslateValue = () => useContext(TranslateContext)

export const translateReducer = (state, action) => {
  switch (action.type) {
    case "UPDATE_LANGUAGE":
      return {
        ...state,
        language: action.payload,
      }

    default:
      return state
  }
}
