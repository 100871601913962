import React from "react"
import { Link, useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import styled from "styled-components"

import { useTranslateValue } from "../../data/TranslateContext"

const Wrapper = styled.section`
  flex-basis: 13rem;
  flex-grow: 3;
`

const FlagWrapper = styled.div`
  display: flex;
  padding: 2.5rem 0 0;

  a {
    display: block;
    margin: 0 1.5rem 1rem 0;

    .gatsby-image-wrapper {
      display: block;
      width: 24px;
      height: 15px;
    }
  }
`

export default function Contact(props) {
  const flagImages = useStaticQuery(graphql`
    query footerFlagImages {
      usa: file(relativePath: { eq: "flags/flag-us.png" }) {
        childImageSharp {
          fixed(width: 24, height: 15, traceSVG: { color: "#004E7D" }) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
      canada: file(relativePath: { eq: "flags/flag-ca.png" }) {
        childImageSharp {
          fixed(width: 24, height: 15, traceSVG: { color: "#004E7D" }) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
      korea: file(relativePath: { eq: "flags/flag-kr.png" }) {
        childImageSharp {
          fixed(width: 24, height: 15, traceSVG: { color: "#004E7D" }) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
      china: file(relativePath: { eq: "flags/flag-cn.png" }) {
        childImageSharp {
          fixed(width: 24, height: 15, traceSVG: { color: "#004E7D" }) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
      au: file(relativePath: { eq: "flags/flag-au.png" }) {
        childImageSharp {
          fixed(width: 24, height: 15, traceSVG: { color: "#004E7D" }) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
    }
  `)

  const [{ language }] = useTranslateValue()

  return (
    <Wrapper>
      {language === "en" && (
        <>
          <p>We want to hear from you! Give us a call or send a message.</p>
          <Link to="/contact">Contact Us</Link>
        </>
      )}

      {language === "fr" && (
        <>
          <p>
            Nous voulons de vos nouvelles! Appelez-nous ou envoyez un message.
          </p>
          <Link to="/contact">Nous Contacter</Link>
        </>
      )}

      <FlagWrapper>
        <Link to="/">
          <Img fixed={flagImages.usa.childImageSharp.fixed} />
        </Link>

        <a
          href="https://www.freal.ca/"
          target="_blank"
          rel="noreferrer noopener"
        >
          <Img fixed={flagImages.canada.childImageSharp.fixed} />
        </a>

        <a
          href="http://www.frealkorea.com/"
          target="_blank"
          rel="noreferrer noopener"
        >
          <Img fixed={flagImages.korea.childImageSharp.fixed} />
        </a>

        <a
          href="http://www.freal.com.cn/"
          target="_blank"
          rel="noreferrer noopener"
        >
          <Img fixed={flagImages.china.childImageSharp.fixed} />
        </a>

        <a
          href="http://freal.com.au/"
          target="_blank"
          rel="noreferrer noopener"
        >
          <Img fixed={flagImages.au.childImageSharp.fixed} />
        </a>
      </FlagWrapper>
    </Wrapper>
  )
}
