import React from "react"
import { Link } from "gatsby"
import styled from "styled-components"

import { hex2rgba } from "../../styles/tools"
import Row from "../../styles/Row"
import { useTranslateValue } from "../../data/TranslateContext"

const BannerWrapper = styled.div`
  background-color: ${props => props.theme.color.bannerBg};
  transition: background 0.2s ease-in-out;

  @media (max-width: 900px) {
    display: none;
  }
`
BannerWrapper.row = styled(Row)`
  display: flex;
  align-items: center;
  justify-content: space-between;

  padding: 0.125rem 0;

  a {
    display: flex;
    align-items: center;
    padding: 0.25rem 1rem;

    text-transform: uppercase;
    font-family: ${props => props.theme.font.heading};
    font-size: 1.125em;
  }
`

const SocialNav = styled.nav`
  display: flex;

  a {
    color: ${props => props.theme.color.secondary};
    transition: color 0.2s ease-in-out;
  }
`

const ToggleNav = styled.nav`
  display: flex;

  a {
    color: ${props => props.theme.color.navigationBg};
    transition: color 0.2s ease-in-out;
  }

  [data-button="toggle"] {
    position: relative;

    display: block;
    padding: 0.25rem;

    span {
      position: absolute;
      left: 0;
      top: 0;

      display: block;
      width: 0px;
      height: 0px;
      overflow: hidden;
      font-size: 0;
      color: transparent;
      user-select: none;
    }

    &::before,
    &::after {
      position: relative;
      display: block;
      content: "";
      transition: all 0.2s ease-in-out;
    }
    &::before {
      margin-top: 0.4rem;
      width: 2.75rem;
      height: calc(100% - 0.75rem);

      border-radius: 2rem;
      background-color: ${props => props.theme.color.navigationBg};
      box-shadow: 0 1px 3px
        ${props => {
          return hex2rgba(props.theme.color.primaryBackground, 0.5)
        }}
        inset;
    }
    &::after {
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-50%);

      width: 1.25rem;
      height: 1.25rem;

      border-radius: 2rem;
      box-shadow: 0 2px 4px
        ${props => {
          return hex2rgba(props.theme.color.primaryBackground, 0.6)
        }};
      background-color: ${props => props.theme.color.highlight};
    }
  }
`

const LanguageToggle = styled.div`
  display: flex;

  button {
    position: relative;

    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 0.5rem;
    padding: 0;

    background: none;
    border: none;
    cursor: pointer;

    color: ${props => props.theme.color.navigationBg};
    font-family: ${props => props.theme.font.heading};
    font-size: 1rem;
    line-height: 1rem;
    transition: all 0.1s ease-in-out;

    &:hover {
      color: #fff;
    }

    &::before {
      position: absolute;
      top: calc(100% + 2px);
      left: 50%;
      transform: translateX(-50%) scaleX(0);
      transform-origin: center;

      width: 100%;
      height: 2px;
      background-color: #fff;
      transition: all 0.1s ease-in-out;

      content: "";
    }

    &[data-active="true"]::before {
      transform: translateX(-50%) scaleX(0.66);
    }

    &:not(:last-child)::after {
      position: absolute;
      top: 50%;
      left: 100%;
      transform: translateX(0.375rem) translateY(-50%);

      display: block;
      margin: 0;
      padding: 0;
      color: #fff;
      opacity: 0.5;

      content: "/";
    }
  }
`

export default function Banner(props) {
  const [{ language }, dispatch] = useTranslateValue()

  return (
    <BannerWrapper>
      <BannerWrapper.row>
        <SocialNav>
          <a
            href="https://www.instagram.com/therealfreal/"
            target="_blank"
            rel="noopener noreferrer"
          >
            Insta
          </a>
          <a
            href="https://www.tiktok.com/@therealfreal"
            target="_blank"
            rel="noopener noreferrer"
          >
            TikTok
          </a>
          <a
            href="https://twitter.com/therealfreal"
            target="_blank"
            rel="noopener noreferrer"
          >
            Twitter
          </a>
        </SocialNav>

        <LanguageToggle>
          <button
            data-active={language === "en"}
            onClick={e => {
              dispatch({ type: "UPDATE_LANGUAGE", payload: "en" })
            }}
          >
            English
          </button>
          <button
            data-active={language === "fr"}
            onClick={e => {
              dispatch({ type: "UPDATE_LANGUAGE", payload: "fr" })
            }}
          >
            Français
          </button>
        </LanguageToggle>

        <ToggleNav>
          <a
            data-button="business"
            href="https://business.freal.com"
            target="_blank"
            rel="nofollow noopener"
          >
            {language === "en" && "Business"}
            {language === "fr" && "Entreprise"}
          </a>
          <a data-button="toggle" href="https://business.freal.com">
            <span>Go to Business Site</span>
          </a>
          <Link data-button="consumer" to="/">
            {language === "en" && "Consumer"}
            {language === "fr" && "Consommateur"}
          </Link>
        </ToggleNav>
      </BannerWrapper.row>
    </BannerWrapper>
  )
}
